import React from 'react'
import { Grid, Box } from '@mui/material';

//components
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar'
import ViewOrder from '../../Components/Common/Factura_restManager/ViewOrder';
import ViewProduct from '../../Components/Common/Factura_restManager/ViewProduct';

function FacturaViewOrderFast() {
  return (
    <>
    <Navbar_sidebar>
    <Grid container spacing={2}>
      {/* Primera columna que ocupa 1/3 del ancho */}
      <Grid item xs={12} md={4}>
       <ViewOrder></ViewOrder>
      </Grid>

      {/* Segunda columna que ocupa 2/3 del ancho */}
      <Grid item xs={12} md={8}>
       <ViewProduct></ViewProduct>
      </Grid>
    </Grid>
    </Navbar_sidebar>
    </>
  )
}

export default FacturaViewOrderFast