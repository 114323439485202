
import React, { useEffect } from 'react';

//componentes
import CircleLateral from './Chart/CircleLateral';

function ChartFacture() {

  return (
    <>
    <div className="flex flex-col md:flex-row gap-4 mt-3">
      <div className="w-full md:w-2/3 p-5 shadow-md">
        <CircleLateral  style={{width: "100%"}} />
      </div>
      <div className="w-full md:w-1/3 p-4 shadow-md">
        <CircleLateral    />
      </div>
    </div>
    </>
  )
}

export default ChartFacture