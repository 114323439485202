import * as React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Badge from '@mui/material/Badge';

//componmentes
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar'

//icons
import TableBarIcon from '@mui/icons-material/TableBar';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';

function FacturacionRestaurant() {
  return (
    <>
    <Navbar_sidebar>
        <div className='container'>
         
         <h1 className='mb-2'>Mode</h1>
         <hr className="border-gray-300  mx-auto" />

            <Stack spacing={5} direction="row" className='mt-5'>
                
            <Box
            sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
                gap: 1,
            }}
            >  
        
            {/* Mesas Button */}
            <Card variant="outlined">   
            <a href='/Facturacion/Table'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
            <TableBarIcon className="h-12 w-12" />
            </Typography>
            <Typography className="mt-2">Mesas</Typography>
            </CardContent> 
            </a>
            </Card>   

            {/* Orden rapida Button */}
            <Card variant="outlined">   
            <a href='/Facturacion/OrderFast'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
            <AddShoppingCartIcon className="h-12 w-12" />
            </Typography>
            <Typography className="mt-2">orden rapida</Typography>
            </CardContent> 
            </a>
            </Card>  

            {/* Orden pendientes Button */}
            <Card variant="outlined">   
                <a href='/'>
                <CardContent className="flex flex-col items-center justify-center">
                <Typography level="title-md" className="flex items-center justify-center">
                <AddShoppingCartIcon className="h-12 w-12" />
                </Typography>
                <Typography className="mt-2">Orden pendientes</Typography>
                </CardContent> 
                </a>
                </Card>  

            {/* Cocina Button */}
            <Card variant="outlined">   
                <a href='/Facturacion/kitchen'>
                <CardContent className="flex flex-col items-center justify-center">
                <Typography level="title-md" className="flex items-center justify-center">
                <Badge badgeContent={10} color="error" className=''>
                <SoupKitchenIcon className="h-12 w-12" />
                </Badge>
                </Typography>
                <Typography className="mt-2">Cocina</Typography>
                </CardContent> 
                </a>
                </Card>  


         </Box>
        </Stack>
      

        {/* Manager */}

        <div className="mt-5">
        <h1 className='mb-2'>Actividad Manager</h1>
         <hr className="border-gray-300  mx-auto" />

         <Stack spacing={5} direction="row" className='mt-5'>
            
         <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        gap: 1,
      }}
    >  
    
            {/* Dashboard Button */}
            <Card variant="outlined">   
            <a href='/Facturacion/Dashboard'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2">Dashboard</Typography>
            </CardContent> 
            </a>
            </Card>   

              {/* Set up Button */}
              <Card variant="outlined">   
            <a href='/Facturacion/Settings'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2">Set up</Typography>
            </CardContent> 
            </a>
            </Card>  

              {/* Open day Button */}
              <Card variant="outlined">   
            <a href='/'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2">Open DAY</Typography>
            </CardContent> 
            </a>
            </Card>  

              {/* Gastos Button */}
              <Card variant="outlined">   
            <a href='/'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2">Gastos</Typography>
            </CardContent> 
            </a>
            </Card>  

            {/* Cerrar dia Button */}
            <Card variant="outlined">   
            <a href='/Facturacion/Close_Day'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2">Close Day</Typography>
            </CardContent> 
            </a>
            </Card> 

                {/* Facturas */}
                <Card variant="outlined">   
            <a href='/Facturacion/Bill'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2">Facturas</Typography>
            </CardContent> 
            </a>
            </Card> 
         

         </Box>
        </Stack>
        </div>

           {/* Report */}

           <div className="mt-5">
        <h1 className='mb-2'>Report</h1>
         <hr className="border-gray-300  mx-auto" />

         <Stack spacing={5} direction="row" className='mt-5'>
            
         <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        gap: 1,
      }}
    >  
    
            {/* Dashboard Button */}
            <Card variant="outlined">   
            <a href='/'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2">Ventas Report</Typography>
            </CardContent> 
            </a>
            </Card>   

              {/* Set up Button */}
              <Card variant="outlined">   
            <a href='/'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2">Menu report</Typography>
            </CardContent> 
            </a>
            </Card>  

              {/* Open day Button */}
              <Card variant="outlined">   
            <a href='/'>
            <CardContent className="flex flex-col items-center justify-center">
            <Typography level="title-md" className="flex items-center justify-center">
           
            </Typography>
            <Typography className="mt-2">Informe laboral</Typography>
            </CardContent> 
            </a>
            </Card>  

           
        
         

         </Box>
        </Stack>
        </div>

        </div>
    </Navbar_sidebar>
    </>
  )
}

export default FacturacionRestaurant