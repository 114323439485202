import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';

//icons
import SearchIcon from '@mui/icons-material/Search';

function ViewProduct() {

    const itemsHome = ["Comida", "Comida", "Comida", "Comida", "Comida", "Comida"];
    const items = ["Comida", "Comida"];
  return (
    <>
       {/* Input */}
    
<div className='mb-5'>
    <div class="relative flex items-center mt-2">
        <span class="absolute">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mx-3 text-gray-400 dark:text-gray-500">
            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>
        </span>

        <input type="email" placeholder="Buscar" class="block w-full py-2.5 text-gray-700 placeholder-gray-400/70 bg-white border border-gray-200 rounded-[40px] pl-11 pr-5 rtl:pr-11 rtl:pl-5 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 shadow-lg"/>
    </div>
</div>


  
    {/* Category */}
      <div className="flex flex-wrap">
      {items.map((item, index) => (
        <Card variant="solid" key={index} className="w-40 m-2" style={{ flex: '0 0 calc(20% - 1rem)' }} sx={{ height: '3.8rem' }}>
       <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
          <Typography textColor="inherit">Comida</Typography>
        </CardContent>
        </Card>
      ))}
    </div>
    <hr></hr>
    <div className="flex flex-wrap">
      {itemsHome.map((item, index) => (
        <Card variant="solid" key={index} className="w-40 m-2" style={{ flex: '0 0 calc(20% - 1rem)' }} sx={{ height: '3.8rem' }}>
       <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
          <Typography textColor="inherit">Comida</Typography>
        </CardContent>
        </Card>
      ))}
    </div>
    </>
  )
}

export default ViewProduct