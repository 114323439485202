import React from 'react'

//components
import Navbar_sidebar from '../../../Components/Common/Navbar_sidebar'

function Bill() {
  return (
    <Navbar_sidebar>
        <div className="flex flex-col md:flex-row gap-4">
      <div className="w-full md:w-2/3 p-4 bg-gray-200">
        <p>Contenido del primer div (2/3 del ancho)</p>
      </div>
      <div className="w-full md:w-1/3 p-4 bg-gray-300">
        <p>Contenido del segundo div (1/3 del ancho)</p>
      </div>
    </div>
    </Navbar_sidebar>
  )
}

export default Bill