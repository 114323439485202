import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
//icons
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function CloseDayColum2() {
  return (
    <>
      <Card
        sx={{
          height: '10vh',
          width: '90%',
          position: 'relative',
          borderRadius: '12px',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 16px'
        }}
      >
        <p className='ml-3'>Turnos de empleo</p>
        <ArrowForwardIosIcon sx={{ fontSize: 20 }} />

      </Card>

      <Card
        sx={{
          height: '10vh',
          width: '90%',
          position: 'relative',
          borderRadius: '12px',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 16px',
          marginTop: '2.5%'
        }}
      >
        <p className='ml-3'>Formato Dinero</p>
        <ArrowForwardIosIcon sx={{ fontSize: 20 }} />

      </Card>

      <div className="flex flex-col items-center justify-center mt-5 mb-5 w-9/12 mx-auto">
      <TextField
        fullWidth
        label="Comentarios"
        id="Comentarios"
        sx={{ width: '80%', height: 'auto' }}
      />

      <div className="mt-5 flex flex-col items-center w-full">
        <p className="mt-5">dsadasd</p>
        <Button variant="contained" className="mt-5">Enviar</Button>
      </div>
    </div>

    
    </>
  )
}

export default CloseDayColum2