import React from 'react'
import Button from '@mui/material/Button';

//components
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar'

function MarkedAsPaid() {
  return (
    <>
    <Navbar_sidebar>
    <div className="container flex flex-col items-center">
    <h1 className='mt-5 mb-3'>Marcado como pagado</h1>
 
   {/* Box cliente */}
   <div className="w-full max-w-md mb-5">
        <p className="text-left">Cliente</p>
        <div className="bg-gray-300 p-4 mt-1">
          <p>Name</p>
          <p>Email</p>
        </div>
      </div>

      {/* Opciones de recibo */}
      <div className="w-full max-w-md mb-5">
        <p className="text-left">Opciones de recibo</p>
        <div className="bg-gray-300 mt-1">
          <a href="#" className="block p-3 bg-gray-300 hover:bg-gray-400 transition duration-200 ">
            <span className='ml-3 '>Enviar recibo</span>
            </a>
          <a href="#" className="block p-3 bg-gray-300 hover:bg-gray-400 transition duration-200 mt-2 ">
          <span className='ml-3'>Enviar por mensaje de texto</span> 
          </a>
        </div>
      </div>
 
    {/* Button */}
  <Button variant="contained" className='mt-5 w-full max-w-md' >
    Listo
  </Button>
</div>

    </Navbar_sidebar>
    </>
  )
}

export default MarkedAsPaid