import React, { useState } from 'react';
import { Stage, Layer, Circle } from 'react-konva';
import NavbarSidebar from '../../Components/Common/Navbar_sidebar';

function FacturacionViewTable() {
  const [circles, setCircles] = useState([{ x: 200, y: 200, radius: 50 }]);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [currentCircleIndex, setCurrentCircleIndex] = useState(null);

  const backgroundStyle = {
    backgroundColor: '#57585e',
    backgroundImage: 'linear-gradient(to right, #b0b0b0 0.5px, transparent 0.5px), linear-gradient(to bottom, #b0b0b0 0.5px, transparent 0.5px)',
    backgroundSize: '30px 30px',
    height: '100vh',
    width: '100vh'
  };

  const handleStageClick = (e) => {
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    if (!e.target.attrs.stroke) { // Only create a circle if not clicked on an existing circle
      setCircles([...circles, { x: pointerPosition.x, y: pointerPosition.y, radius: 50 }]);
      setContextMenuVisible(false);
    }
  };

  const handleCircleContextMenu = (e, index) => {
    e.evt.preventDefault();
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    setContextMenuPosition({ x: pointerPosition.x, y: pointerPosition.y });
    setCurrentCircleIndex(index);
    setContextMenuVisible(true);
  };

  const handleRemove = () => {
    const newCircles = circles.slice();
    newCircles.splice(currentCircleIndex, 1);
    setCircles(newCircles);
    setContextMenuVisible(false);
  };

  const handleChange = () => {
    const newCircles = circles.slice();
    newCircles[currentCircleIndex] = { ...newCircles[currentCircleIndex], radius: newCircles[currentCircleIndex].radius + 10 };
    setCircles(newCircles);
    setContextMenuVisible(false);
  };

  return (
    <>
      <NavbarSidebar>
        <div style={backgroundStyle} onContextMenu={(e) => e.preventDefault()}>
          <Stage
            width={window.innerWidth}
            height={window.innerHeight}
            onClick={handleStageClick}
          >
            <Layer>
              {circles.map((circle, index) => (
                <Circle
                  key={index}
                  x={circle.x}
                  y={circle.y}
                  stroke="black"
                  radius={circle.radius}
                  draggable
                  onContextMenu={(e) => handleCircleContextMenu(e, index)}
                  onDragEnd={(e) => {
                    const newCircles = circles.slice();
                    newCircles[index] = { ...circle, x: e.target.x(), y: e.target.y() };
                    setCircles(newCircles);
                  }}
                />
              ))}
            </Layer>
          </Stage>
          {contextMenuVisible && (
            <div
              id="menu"
              style={{
                position: 'absolute',
                top: contextMenuPosition.y,
                left: contextMenuPosition.x,
                backgroundColor: 'white',
                boxShadow: '0 0 5px grey',
                borderRadius: '3px',
                display: 'block',
              }}
            >
              <button onClick={handleChange}>Pulse</button>
              <button onClick={handleRemove}>Delete</button>
            </div>
          )}
        </div>
      </NavbarSidebar>
    </>
  );
}

export default FacturacionViewTable;



