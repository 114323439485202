import React from 'react';
import Masonry from 'react-masonry-css';
import Navbar_sidebar from '../../../Components/Common/Navbar_sidebar';


//iocns
import MoreVertIcon from '@mui/icons-material/MoreVert';

const cardsData = [
  { id: 1, height: 300, color: 'bg-red-200' },
  { id: 2, height: 150, color: 'bg-blue-200' },
  { id: 3, height: 300, color: 'bg-green-200' },
  { id: 4, height: 250, color: 'bg-pink-200' },
  { id: 5, height: 200, color: 'bg-yellow-200' },
];

function Kitchen() {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2
  };

  return (
    <Navbar_sidebar>
     <div className="flex justify-between items-center p-4">
        <span className="text-lg">Cocina</span>
        <div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg border-2 border-blue-500"
            style={{ borderRadius: '25px' }}
          >
           Ordenes Finalizadas
          </button>
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg border-2 border-green-500 ml-4"
            style={{ borderRadius: '25px' }}
          >
           Agrupacion
          </button>
          <a
            className="hover:bg-gray-200  font-bold py-2 px-4 ml-2"
            style={{ borderRadius: '5px' }}
          >
            <MoreVertIcon></MoreVertIcon>
          </a>
        </div>
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="flex -ml-4 w-auto"
        columnClassName="pl-4 bg-clip-padding"
      >
        {cardsData.map(card => (
          <div
            key={card.id}
            className={`mb-4 ${card.color} rounded-lg p-4 shadow-md`}
            style={{ height: `${card.height}px`}} // Ajusta el ancho aquí
          >
            #{card.id}
           <p>2:31 PM</p>

          </div>
        ))}
      </Masonry>
    </Navbar_sidebar>
  );
}

export default Kitchen;