
import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
function ViewUpdateOrder() {

    //menu bUTTON MOVIL
    const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


    const itemsHome = ["Comida", "Comida", "Comida", "Comida", "Comida", "Comida"];
    const items = ["Comida", "Comida"];

  return (
    <>
    <div className="flex gap-4 mt-3">
      {/* Primera columna */}
      <div className="w-2/3 flex items-start gap-2">
        <Button
      variant="contained"
      color="primary"
      sx={{
        width: 46,
        height: 46,
        borderRadius: '50%',
        minWidth: 0, // Para asegurar que el botón sea circular
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AddIcon></AddIcon>
       </Button>
       <Button
      variant="outlined"
      color="primary"
      sx={{
        width: 46,
        height: 46,
        borderRadius: '50%',
        minWidth: 0, // Para asegurar que el botón sea circular
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span>1</span>
       </Button>
       <Button
      variant="contained"
      color="primary"
      sx={{
        width: 46,
        height: 46,
        borderRadius: '50%',
        minWidth: 0, // Para asegurar que el botón sea circular
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <RemoveIcon></RemoveIcon>
       </Button>
      </div>
      {/* Segunda columna */}
      {/* Botones que se muestran en pantallas pequeñas */}
      <div className="w-1/3 flex items-end gap-2 md:hidden">
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
      </div>
      
      {/* Botón que se muestra en pantallas medianas y grandes */}
      <div className="hidden md:flex md:w-full justify-end gap-2">
        <Button variant="outlined">Detalle</Button>
        <Button variant="outlined">Remove</Button>
        <Button variant="outlined">Done</Button>
      </div>

    </div>

    <div className="container mt-4">
        <h1 className='mb-3 p-3'>NAme producto</h1>

  
    {/* Category */}
      <div className="flex flex-wrap">
      {items.map((item, index) => (
        <Card variant="solid" key={index} className="w-40 m-2" style={{ flex: '0 0 calc(20% - 1rem)' }} sx={{ height: '3.8rem' }}>
       <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
          <Typography textColor="inherit">Comida</Typography>
        </CardContent>
        </Card>
      ))}
    </div>
    <hr></hr>
    <div className="flex flex-wrap">
      {itemsHome.map((item, index) => (
        <Card variant="solid" key={index} className="w-40 m-2" style={{ flex: '0 0 calc(20% - 1rem)' }} sx={{ height: '3.8rem' }}>
       <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: '100%' }}>
          <Typography textColor="inherit">Comida</Typography>
        </CardContent>
        </Card>
      ))}
    </div>
    </div>

    </>
  )
}

export default ViewUpdateOrder