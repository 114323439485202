import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

//icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

function ViewOrder() {
  return (
    <>
   <Card sx={{ height: '80vh', position: 'relative', borderRadius: '12px',
   boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)'
    }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <a href='/Facturacion/restaurant'><ArrowBackIcon /></a>
          <Box sx={{ ml: 1 }}>
            Regresar
          </Box>
        </Box>
        </Typography>
      </CardContent>

      <div className="container mx-auto ">
  {/* Contenedor con scroll */}
  <div className="bg-gray-100" style={{ overflowY: 'auto' }}>
    {/* Título */}
    <div className="bg-gray-200">
      <p className="ml-3 p-1">COMIDA</p>
    </div>

    {/* Ítems */}
    <div className="p-4">
      {/* Primer ítem */}
      <div className="flex justify-between items-center mb-4">
        {/* Columna izquierda */}
        <div className="w-1/2 flex items-center">
          <span className="text-center ml-3 bg-gray-300 p-1" style={{ width: '35px' }}>1</span>
          <span className="text-right ml-5">ratatouille ..</span>
        </div>
        {/* Columna derecha */}
        <div className="w-1/2 flex justify-end items-center">
          <span className="text-right mr-5">$500.000</span>
          <span className="text-left" style={{ width: '75px' }}>$1.200.000</span>
        </div>
      </div>

      {/* Segundo ítem */}
      <div className="flex justify-between items-center mb-4">
        {/* Columna izquierda */}
        <div className="w-1/2 flex items-center">
          <span className="text-center ml-3 bg-gray-300 p-1" style={{ width: '35px' }}>1</span>
          <span className="text-right ml-5">ratatouille ..</span>
        </div>
        {/* Columna derecha */}
        <div className="w-1/2 flex justify-end items-center">
          <span className="text-right mr-5">$500.000</span>
          <span className="text-left" style={{ width: '75px' }}>$1.200.000</span>
        </div>
      </div>

      {/* Agrega más ítems aquí si es necesario */}
    </div>
  </div>
</div>

       

         {/*Bottom  */}
        <div className="absolute bottom-0 w-full">
        <hr className="bg-gray-200 p-0.5"></hr>
        {/* costo and taxes */}
        <div className='container p-4'>
        <div className="flex justify-between">
         <p>Subtotal:</p>
         <p>$50000</p>
       </div>
       <div className="flex justify-between">
         <p>Impuestos:</p>
         <p>$1000</p>
       </div>
       <Button variant="contained" sx={{ width: '100%', marginTop: '5%' }}>
        Pedir
        </Button>
       </div>  {/* End costo and taxes */}

      </div>

    </Card>
    </>
  )
}

export default ViewOrder