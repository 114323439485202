
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

//componetes
import Navbar_sidebar from '../../../Components/Common/Navbar_sidebar'
import TableEmployee from '../../../Components/Common/Factura_restManager/Components/TableEmployee';


function Setting() {
  return (
    <Navbar_sidebar>

     <div className="container">

      <h1 className='mt-1  p-4'>Configuracion</h1>

     <div>
    {/* Empleados */}
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Empleados
        </AccordionSummary>
        <AccordionDetails>
         <TableEmployee></TableEmployee>
        </AccordionDetails>
      </Accordion>
    {/* Propinas */}
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
        Propinas
        </AccordionSummary>
        <AccordionDetails>
        <TextField
          required
          id="standard-required"
          label="Required"
          defaultValue="Hello World"
          variant="standard"
        />
        </AccordionDetails>
        <AccordionActions>
          {/* <Button>Cancel</Button>*/}
          <Button>Guardar</Button> 
        </AccordionActions>
      </Accordion>
      {/* Iva */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
         Iva
        </AccordionSummary>
        <AccordionDetails>
        <TextField
          required
          id="standard-required"
          label="Required"
          defaultValue="Hello World"
          variant="standard"
        />
        </AccordionDetails>
        <AccordionActions>
          <Button>Guardar</Button> 
        </AccordionActions>
      </Accordion>
        {/* Productos */}
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Productos
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion> 
        {/* Create Menu Movil */}
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Create Menu Movil
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
    </div>
     </div>

    </Navbar_sidebar>
  )
}

export default Setting