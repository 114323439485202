import React from 'react';
import { Route } from 'react-router-dom';

//componentes
import FacturacionHome from '../../Modules/Facturacion_restaurantManager/FacturacionHome';
import FacturacionRestaurant from '../../Modules/Facturacion_restaurantManager/FacturacionRestaurant';
import CloseDay from '../../Modules/Facturacion_restaurantManager/Manager/CloseDay';
import FacturacionViewTable from '../../Modules/Facturacion_restaurantManager/FacturacionViewTable';
import FacturaViewOrderFast from '../../Modules/Facturacion_restaurantManager/FacturaViewOrderFast';
import ChooseMethodPay from '../../Modules/Facturacion_restaurantManager/ChooseMethodPay';
import MarkedAsPaid from '../../Modules/Facturacion_restaurantManager/MarkedAsPaid';
import Setting from '../../Modules/Facturacion_restaurantManager/Manager/Setting';
import Dashboard from '../../Modules/Facturacion_restaurantManager/Manager/Dashboard';
import Kitchen from '../../Modules/Facturacion_restaurantManager/Cocina/Kitchen';
import Bill from '../../Modules/Facturacion_restaurantManager/Manager/Bill';
import UpdateOrder from '../../Modules/Facturacion_restaurantManager/UpdateOrder';



const Factura_restManager = (
    <>
      {/* Rutas Facturacion */}
       <Route path="/Facturacion/Home" element={< FacturacionHome/>} /> 
       <Route path="/Facturacion/restaurant" element={< FacturacionRestaurant/>} /> 
       <Route path="/Facturacion/Close_Day" element={< CloseDay/>} />
       <Route path="/Facturacion/Table" element={< FacturacionViewTable/>} /> 
       <Route path="/Facturacion/OrderFast" element={< FacturaViewOrderFast/>} /> 

       <Route path="/Facturacion/Dashboard" element={< Dashboard/>} /> 
       <Route path="/Facturacion/Settings" element={< Setting/>} /> 
       <Route path="/Facturacion/kitchen" element={< Kitchen/>} /> 
       <Route path="/Facturacion/Bill" element={< Bill/>} /> 
       <Route path="/Facturacion/UpdateOrder" element={< UpdateOrder/>} /> 

       <Route path="/Facturacion/PaymentMethod" element={< ChooseMethodPay/>} /> 
       <Route path="/Facturacion/MarkedAsPaid" element={< MarkedAsPaid/>} /> 
    </>
  );
  
  export default Factura_restManager;
  