import React from 'react'
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar'
function ImpuestosHome() {
  return (
    <Navbar_sidebar>
     ImpuestosHome
    </Navbar_sidebar>
  )
}

export default ImpuestosHome