import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';


function TableCheck({ rows, columns }) {
    return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
           rows={rows}
           columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}

export default TableCheck