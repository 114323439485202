import React from 'react'
import Card from '@mui/joy/Card';
import { Link } from 'react-router-dom';
import AspectRatio from '@mui/joy/AspectRatio';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';

//components
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar'

//icon
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';

function FacturacionHome() {
  return (
    <>
    <Navbar_sidebar>

    <div className="container">

    <div className="flex justify-between items-center mb-5 mt-3">
    <h1 className="text-2xl flex-shrink-0 ml-5" >
     <BusinessIcon/> Sucursales
    </h1>
    </div>
    <button href='/Facturacion/restaurant' className='mb-3'>ir a mode</button>

    <Grid container spacing={3}>
      {/* Card 1 */}
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card
          variant="outlined"
          sx={{
            boxShadow: 3,
            cursor: 'pointer',
            height: { xs: '200px', sm: '200px', md: '200px', lg: '250px' },
            width: '100%',
            '&:hover': {
              boxShadow:
                '0 4px 20px 0 rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.2)',
            },
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <AddIcon fontSize="large" />
          </CardContent>
        </Card>
      </Grid>

    </Grid>

    </div>
    </Navbar_sidebar>
    </>
  )
}

export default FacturacionHome