import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

function CircleLateral() {
    const chartRef = useRef(null); // Ref para el canvas
  
    useEffect(() => {
      let chartInstance = null;
  
      // Función para crear el gráfico
      const createChart = () => {
        const data = {
          labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
          datasets: [{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            borderWidth: 1
          }]
        };
  
        const config = {
          type: 'bar',
          data: data,
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        };
  
        if (chartRef.current) {
          const ctx = chartRef.current.getContext('2d');
          chartInstance = new Chart(ctx, config);
        }
      };
  
      // Llamada a la función para crear el gráfico
      createChart();
  
      // Cleanup: Destruir el gráfico al desmontar el componente
      return () => {
        if (chartInstance) {
          chartInstance.destroy();
        }
      };
    }, []);
  
    return (
      <div>
        <canvas ref={chartRef} id="myChart"></canvas>
      </div>
    );
  }

export default CircleLateral