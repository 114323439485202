import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useState } from 'react';

//components
import Navbar_sidebar from '../../Components/Common/Navbar_sidebar'

function ChooseMethodPay() {

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
    <Navbar_sidebar>

    <div className="container flex flex-col items-center">
  <h1 className='mt-5 mb-3'>Elegir método de pago</h1>
  {/* Box */}
  <div>
      <div className="bg-gray-300 mt-5 p-4 w-full max-w-md mb-5">
        <div className="flex justify-between items-center">
          <h1 className="text-lg">Cash</h1>
          <div className="flex items-center">
            <input
              type="radio"
              id="cashRadio"
              name="paymentOption"
              className="form-radio h-5 w-5 text-blue-600 mr-2"
              checked={selectedOption === 'cash'}
              onChange={() => handleOptionChange('cash')}
            />
            <label htmlFor="cashRadio" className="text-gray-600">Seleccionado</label>
          </div>
        </div>


        <div className="flex justify-between items-center mt-5 mb-5">
          <h1 className="text-lg">Tarjeta</h1>
          <div className="flex items-center">
            <input
              type="radio"
              id="cardRadio"
              name="paymentOption"
              className="form-radio h-5 w-5 text-blue-600 mr-2"
              checked={selectedOption === 'card'}
              onChange={() => handleOptionChange('card')}
            />
            <label htmlFor="cardRadio" className="text-gray-600">Seleccionado</label>
          </div>
        </div>

        <div className="flex justify-between items-center mt-5 mb-5">
          <h1 className="text-lg">Transferencia</h1>
          <div className="flex items-center">
            <input
              type="radio"
              id="transferRadio"
              name="paymentOption"
              className="form-radio h-5 w-5 text-blue-600 mr-2"
              checked={selectedOption === 'transfer'}
              onChange={() => handleOptionChange('transfer')}
            />
            <label htmlFor="transferRadio" className="text-gray-600">Seleccionado</label>
          </div>
        </div>
      </div>

      {selectedOption === 'cash' && (
        <div className="box bg-gray-300 mt-5 p-4 w-full max-w-md mb-5">
          <h1>Contenido adicional solo para pago en efectivo</h1>
        </div>
      )}
    </div>
  <div className="box">

  </div>
    {/* Button */}
  <Button variant="contained" className='mt-5 w-full max-w-md' href='/Facturacion/MarkedAsPaid'>
    Marcar como pagado
  </Button>
</div>


    </Navbar_sidebar>
    </>
  )
}

export default ChooseMethodPay