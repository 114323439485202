import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

import Button from '@mui/joy/Button';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import SvgIcon from '@mui/joy/SvgIcon';

//components
import Navbar_sidebar from '../../../Components/Common/Navbar_sidebar';
import ChartFacture from '../../../Components/Common/Factura_restManager/Components/ChartFacture';
//icons
import SellIcon from '@mui/icons-material/Sell';

function Dashboard() {
  return (
    <>
    <Navbar_sidebar>
    <div className="flex flex-wrap ">
      {/* card 1 */}
      <Card
  variant="solid"
  color="primary"
  invertedColors
  className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2 m-2"
  sx={{ height: '6.5rem' }}
>
  <CardContent
    sx={{
      display: 'flex',
      alignItems: 'center', // Centers children vertically
      justifyContent: 'flex-start', // Aligns children horizontally
      padding: '0.5rem 1rem', // Adjust padding as needed
    }}
    orientation="horizontal"
  >
    <SellIcon
      sx={{
        marginRight: '1rem', // Adjust as needed
        fontSize: '2rem', // Adjust icon size as needed
      }}
    />
    <div>
      <Typography level="body-md" sx={{ marginBottom: '0.25rem' }}>Gross profit</Typography>
      <Typography level="h2" sx={{ fontSize: '1.1rem' }}>$ 432.6M</Typography>
    </div>
  </CardContent>
</Card>
      {/* card 2 */}
      <Card
        variant="solid"
        className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2 m-2"
        sx={{ height: '5.5rem' }}
      >
        <CardContent>
          <Typography textColor="inherit">Comida</Typography>
        </CardContent>
      </Card>
      {/* card 3 */}
      <Card
        variant="solid"
        className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2 m-2"
        sx={{ height: '5.5rem' }}
      >
        <CardContent>
          <Typography textColor="inherit">Comida</Typography>
        </CardContent>
      </Card>
      {/* card 4 */}
      <Card
        variant="solid"
        className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 p-2 m-2"
        sx={{ height: '5.5rem' }}
      >
        <CardContent>
          <Typography textColor="inherit">Comida</Typography>
        </CardContent>
      </Card>
    </div>

    {/* Chart */}
    <ChartFacture></ChartFacture>
    
    </Navbar_sidebar>
    </>
  )
}

export default Dashboard